import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

interface Filter {
  label: string;
  value: string;
}

interface UserState {
  sliceUsers: Array<any>;
  isLoading: boolean;
  error: string | null;
  totalCount: number;
  filters: Filter[];
  role: string | null;
  query: string;
}

const initialState: UserState = {
  sliceUsers: [],
  isLoading: false,
  error: null,
  totalCount: 0,
  filters: [],
  role: null,
  query: '',
};

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async ({
    __raw,
    pageNumber,
    limit,
    role,
    query,
  }: {
    __raw: string;
    pageNumber: number;
    limit: number;
    role: string | null;
    query: string; // Added query parameter
  }) => {
    try {
      const roleFilter = role ? `&role=${role}` : '';
      const queryFilter = query ? `&filter=${encodeURIComponent(query)}` : ''; // Encode query
      const response = await axios.get(
        `https://staging.id.sonio-group.com/api/console/v1/users?page=${pageNumber}&per_page=${limit}${queryFilter}${roleFilter}`,
        {
          headers: {
            Authorization: `Bearer ${__raw}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchTotalCount = createAsyncThunk(
  'users/fetchTotalCount',
  async ({
    __raw,
    role,
    query,
  }: {
    __raw: string;
    role: string | null;
    query: string;
  }) => {
    try {
      const roleFilter = role ? `&role=${role}` : '';
      const queryFilter = query ? `?filter=${encodeURIComponent(query)}` : '';
      const response = await axios.get(
        `https://staging.id.sonio-group.com/api/console/v1/users/total${roleFilter}${queryFilter}`,
        {
          headers: {
            Authorization: `Bearer ${__raw}`,
          },
        },
      );
      return response.data.totalCount;
    } catch (error) {
      throw error;
    }
  },
);

export const userSlice = createSlice({
  name: 'sliceUser',
  initialState,
  reducers: {
    setRoleFilter(state, action: PayloadAction<string | null>) {
      state.role = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUsers.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sliceUsers = action.payload;
        state.error = null;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch users';
        state.sliceUsers = [];
      })
      .addCase(fetchTotalCount.fulfilled, (state, action) => {
        state.totalCount = action.payload;
      })
      .addCase(fetchTotalCount.rejected, (state, action) => {
        state.error = action.error.message || 'Failed to fetch total count';
      });
  },
});

export const { setRoleFilter } = userSlice.actions;

export default userSlice.reducer;
