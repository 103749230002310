// file to hold react routes as constants
const authBase = '/auth';

const routeNames = {
  index: '/',
  auth: {
    login: '/login',
    register: authBase + '/register',
    recoverPassword: authBase + '/recover-password',
  },
};

export default routeNames;
