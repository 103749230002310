import React from 'react';
import { ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';

import { PureLightTheme } from './schemes/PureLightTheme';

export const ThemeContext = React.createContext((): void => {});

const ThemeProviderWrapper: React.FC = props => {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={PureLightTheme}>{props.children}</ThemeProvider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
