import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import organization from './slices/organization.slice';
import stats from './slices/stats.slice';
import sliceTransaction from './slices/transaction.slice';
import user from './slices/user.slice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['sliceTransaction', 'organization'],
};

export const rootReducers = combineReducers({
  organization,
  sliceTransaction,
  user,
  stats,
});

const persistedReducer = persistReducer(persistConfig, rootReducers);

const setupStore = () =>
  configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware({
      serializableCheck: false,
    }),
    devTools: true,
  });

type AppStore = ReturnType<typeof setupStore>;
type RootState = ReturnType<AppStore['getState']>;
type AppDispatch = AppStore['dispatch'];

export type { AppDispatch, RootState };

export const store = setupStore();
export const persistedStore = () => persistStore(store);

export default store;
