const Items = () => {
  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    nodeType: string,
    parentNode: string,
    nodePosition?: any,
  ) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.setData('application/reactflow/parent', parentNode);
    if (nodePosition) {
      event.dataTransfer.setData(
        'application/reactflow/position',
        JSON.stringify(nodePosition),
      );
    }
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <aside>
      <div className="description">
        You can drag these nodes to the pane on the right.
      </div>
      <div
        id="start"
        className="dndnode input"
        onDragStart={event => onDragStart(event, 'Start', '')}
        draggable
      >
        Start
      </div>
      <div
        className="dndnode"
        onDragStart={event => onDragStart(event, 'QR Code', '')}
        draggable
      >
        QR Code
      </div>
      <div
        className="dndnode"
        onDragStart={event => onDragStart(event, 'Terms & Conditions', '')}
        draggable
      >
        Terms & Conditions
      </div>
      <div
        className="dndnode"
        onDragStart={event =>
          onDragStart(event, 'Identity Card', '2', { x: 50, y: 70 })
        }
        draggable
      >
        Identity Card
      </div>
      <div
        className="dndnode"
        onDragStart={event =>
          onDragStart(event, 'German Residence Permit', '2', { x: 50, y: 170 })
        }
        draggable
      >
        German Residence Permit
      </div>
      <div
        className="dndnode"
        onDragStart={event =>
          onDragStart(event, 'Passport', '2', { x: 50, y: 270 })
        }
        draggable
      >
        Passport
      </div>
      <div
        className="dndnode"
        onDragStart={event => onDragStart(event, 'ID Font Tutorial', '')}
        draggable
      >
        ID Font Tutorial
      </div>
      <div
        className="dndnode"
        onDragStart={event => onDragStart(event, 'ID Tutorial', '')}
        draggable
      >
        ID Tutorial
      </div>
      <div
        className="dndnode"
        onDragStart={event => onDragStart(event, 'Passport Tutorial', '')}
        draggable
      >
        Passport Tutorial
      </div>
    </aside>
  );
};

export default Items;
