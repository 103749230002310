import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import UnfoldMoreTwoToneIcon from '@mui/icons-material/UnfoldMoreTwoTone';
import {
  alpha,
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Popover,
  styled,
  Typography,
} from '@mui/material';
import dazenbetLogo from 'src/assets/Daznbet.svg';
import finductiveLogo from 'src/assets/Finductive_logo.svg';
import finyozLogo from 'src/assets/Finyoz-Logo.svg';
import intertopsLogo from 'src/assets/Intertops.svg';
import mestLogo from 'src/assets/Mest-Logo.svg';
import payladoLogo from 'src/assets/Paylado.svg';
import sonnenspieleLogo from 'src/assets/Sonnenspiele-Logo.svg';
import tipicoLogo from 'src/assets/Tipico-Logo.svg';
import StandaloneToggleButton from 'src/components/ToggleButton';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';
import {
  fetchOrganizations,
  setSelectedOrganization,
} from 'src/store/slices/organization.slice';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
    padding: ${theme.spacing(1)};
    background-color: ${alpha(theme.colors.alpha.black[100], 0.08)};

    .MuiButton-label {
      justify-content: flex-start;
    }

    &:hover {
      background-color: ${alpha(theme.colors.alpha.black[100], 0.12)};
    }
`,
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
`,
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
    text-align: left;
    padding-left: ${theme.spacing(1)};
`,
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.sidebar.menuItemColor};
    display: block;

    &.popoverTypo {
      color: ${theme.palette.secondary.main};
    }
`,
);

const clientsLogo = {
  org_vg0goBkpsJsRq0x3: payladoLogo,
  org_RBgolbV5Zy7YYgv1: tipicoLogo,
  org_2fZNwBUgHU3nKDjv: finductiveLogo,
  org_2DsIvIWfkfEKl9ET: sonnenspieleLogo,
  org_cjt0NRkbPxSBHMo2: intertopsLogo,
  org_yvMOnaQsGfu04aLE: dazenbetLogo,
  org_RvQvauoG8sINawa9: mestLogo,
  org_n8cPICNMjp449WZQ: finyozLogo,
};

const getClientLogo = id => clientsLogo[id] || 'unknown';

const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1);

function SidebarTopSection() {
  const { user, getIdTokenClaims } = useAuth0();
  const dispatch = useAppDispatch();
  const { organizations, selectedOrganization } = useAppSelector(
    state => state.organization,
  );
  const [isOpen, setOpen] = useState<boolean>(false);
  const ref = useRef<any>(null);
  const getOrganizations = useCallback(async () => {
    try {
      const { __raw } = await getIdTokenClaims();
      dispatch(fetchOrganizations({ sub: user.sub, __raw }));
    } catch (err) {
      //setError
    }
  }, [dispatch, getIdTokenClaims, user.sub]);

  useEffect(() => {
    getOrganizations();
  }, [getOrganizations]);
  useEffect(() => {
    if (organizations.length > 1) {
      setSelectedOrganization(organizations[0]);
    }
  }, [organizations]);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  return (
    <>
      <Typography className="pt-4">Organization</Typography>
      {organizations && organizations.length >= 1 && selectedOrganization && (
        <UserBoxButton
          fullWidth
          color="secondary"
          ref={ref}
          onClick={handleOpen}
        >
          <Avatar
            variant="rounded"
            alt={'organization'}
            src={getClientLogo(selectedOrganization.id)}
          />
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <UserBoxText>
              <UserBoxLabel variant="body1">
                {capitalizeFirstLetter(selectedOrganization.name)}
              </UserBoxLabel>
            </UserBoxText>

            <UnfoldMoreTwoToneIcon
              fontSize="small"
              sx={{
                ml: 1,
              }}
            />
          </Box>
        </UserBoxButton>
      )}
      {organizations && organizations.length > 1 && selectedOrganization && (
        <>
          <Popover
            disableScrollLock
            anchorEl={ref.current}
            onClose={handleClose}
            open={isOpen}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
          >
            <MenuUserBox
              sx={{
                minWidth: 210,
              }}
              display="flex"
            >
              <Avatar
                variant="rounded"
                alt={'user.name'}
                src={getClientLogo(selectedOrganization.id)}
              />
              <UserBoxText className="flex items-center">
                <UserBoxLabel className="popoverTypo" variant="body1">
                  {capitalizeFirstLetter(selectedOrganization.name)}
                </UserBoxLabel>
              </UserBoxText>
            </MenuUserBox>
            <Divider
              sx={{
                mb: 0,
              }}
            />
            <List
              sx={{
                p: 1,
              }}
              component="nav"
            >
              <div>
                {organizations &&
                  organizations.map((org, index) => (
                    <ListItem
                      key={index}
                      onClick={() => {
                        dispatch(setSelectedOrganization(org));
                        handleClose();
                      }}
                      button
                    >
                      <Avatar
                        variant="rounded"
                        alt={'user.name'}
                        src={getClientLogo(org.id)}
                      />
                      <ListItemText
                        className="pl-4"
                        primary={capitalizeFirstLetter(org.name)}
                      />
                    </ListItem>
                  ))}
              </div>
            </List>
          </Popover>
          <StandaloneToggleButton />
        </>
      )}
    </>
  );
}

export default SidebarTopSection;
