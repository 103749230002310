import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

interface Filter {
  label: string;
  value: string;
}

interface TransactionState {
  sliceTransactions: Array<any>;
  isLoading: boolean;
  error: string | null;
  totalCount: number;
  filters: Filter[];
  liveData: boolean;
}

const initialState: TransactionState = {
  sliceTransactions: [],
  isLoading: false,
  error: null,
  totalCount: 0,
  filters: [],
  liveData: false,
};

export const fetchTransactions = createAsyncThunk(
  'transactions/fetchTransactions',
  async ({
    __raw,
    pageNumber,
    limit,
    authOrgId,
    filters,
    liveData,
  }: {
    __raw: string;
    pageNumber: number;
    limit: number;
    authOrgId: string;
    filters: Filter[];
    liveData: boolean;
  }) => {
    try {
      const filterParams = filters
        .map(filter => `${filter.label}=${filter.value}`)
        .join('&');
      const baseUrl = liveData
        ? 'https://id.sonio-group.com/api/console/v1/sessions'
        : 'https://staging.id.sonio-group.com/api/console/v1/sessions';
      const response = await axios.get(
        `${baseUrl}?page=${pageNumber}&authOrgId=${authOrgId}&limit=${limit}&${filterParams}`,
        {
          headers: {
            Authorization: `Bearer ${__raw}`,
          },
        },
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const transactionSlice = createSlice({
  name: 'sliceTransaction',
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<Filter[]>) => {
      state.filters = action.payload;
    },
    clearFilters: state => {
      state.filters = [];
    },
    setLiveData: (state, action: PayloadAction<boolean>) => {
      state.liveData = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTransactions.pending, state => {
        state.isLoading = true;
        state.error = null;
        state.totalCount = 0;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sliceTransactions = action.payload.sessions;
        state.totalCount = action.payload.totalCount;
        state.error = null;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch transactions';
        state.sliceTransactions = [];
        state.totalCount = 0;
      });
  },
});
export const { setFilters, clearFilters, setLiveData } =
  transactionSlice.actions;
export default transactionSlice.reducer;
