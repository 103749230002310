import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import Authenticated from 'src/components/Authenticated';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Builder from 'src/content/pages/Flows/Builder';
import Rules from 'src/content/pages/Flows/Rules';
import BaseLayout from 'src/layouts/BaseLayout';
import SidebarLayout from 'src/layouts/SidebarLayout';

import baseRoutes from './base';

const Loader = Component => props =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const SingleTransaction = Loader(
  lazy(() => import('src/content/pages/Transactions/singleTransaction')),
);

const Sessions = Loader(lazy(() => import('src/content/pages/Sessions')));

const Statistics = Loader(lazy(() => import('src/content/pages/Statistics')));

const Login = Loader(lazy(() => import('src/content/Auth/Login')));

const Users = Loader(lazy(() => import('src/content/pages/Users')));

const UserProfile = Loader(lazy(() => import('src/content/pages/UserProfile')));

const router: RouteObject[] = [
  // auth route
  // {
  //   path: '/login',
  //   children: authRoutes,
  // },
  // Sidebar Layout
  {
    path: '',
    element: (
      <Authenticated>
        <SidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: `/transactions/sessions/:transactionId`,
        element: <SingleTransaction />,
      },
      {
        path: `/transactions/sessions`,
        element: <Sessions />,
      },
      {
        path: `/transactions/statistics`,
        element: <Statistics />,
      },
      {
        path: '/users',
        element: <Users />,
      },
      {
        path: '/user/profile',
        element: <UserProfile />,
      },
    ],
  },
  {
    path: '/flows',
    element: <SidebarLayout />,
    children: [
      {
        path: 'builder',
        element: <Builder />,
      },
      {
        path: 'rules',
        element: <Rules />,
      },
    ],
  },
  {
    path: 'status',
    element: (
      <Authenticated>
        <BaseLayout />
      </Authenticated>
    ),
    children: baseRoutes,
  },
  { path: '/login', element: <Login /> },
  {
    path: '*',
    element: <Navigate to="/login" replace />,
  },
];

export default router;
