import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

interface BaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  return <div className="flex-1 h-full">{children || <Outlet />}</div>;
};

export default BaseLayout;
