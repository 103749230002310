import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import ScrollTop from 'src/hooks/useScrollTop';
import * as serviceWorker from 'src/serviceWorker';

import ReduxProvider from './store/redux/provider';

import 'nprogress/nprogress.css';
import './index.css';

ReactDOM.render(
  <HelmetProvider>
    <ReduxProvider>
      <Auth0Provider
        domain="dev-sitk24ss2iowl228.eu.auth0.com"
        clientId="PXQf6LMyGxcF3efkqCOJ1EO1wfSfqzR6"
        authorizationParams={{
          redirect_uri: window.location.origin,
        }}
      >
        <SidebarProvider>
          <BrowserRouter>
            <ScrollTop />
            <App />
          </BrowserRouter>
        </SidebarProvider>
      </Auth0Provider>
    </ReduxProvider>
  </HelmetProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
