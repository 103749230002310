import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

interface Filter {
  label: string;
  value: string;
}

export interface sessionStatsData {
  Fail: number;
  NeedsReview: number;
  Pass: number;
  Pending: number;
  Total: number;
}

interface MonthlyStats {
  Date: {
    Year: number;
    Month: number;
  };
  TotalCount: number;
  TotalValidationsPass: number;
  TotalValidationsNeedsReview: number;
  TotalValidationsFail: number;
}

interface StatisticState {
  sliceStatistics: Array<any>;
  selectedDateStats: sessionStatsData;
  yearlyStatistics: MonthlyStats[];
  isLoading: boolean;
  error: string | null;
  filters: Filter[];
  liveData: boolean;
}

const initialState: StatisticState = {
  sliceStatistics: [],
  selectedDateStats: { Total: 0, Pass: 0, Fail: 0, NeedsReview: 0, Pending: 0 },
  yearlyStatistics: [],
  isLoading: false,
  error: null,
  filters: [],
  liveData: false,
};

export const fetchStatistics = createAsyncThunk(
  'statistics/fetchStatistics',
  async ({
    __raw,
    period,
    authOrgId,
    liveData,
  }: {
    __raw: string;
    period: string;
    authOrgId: string;
    liveData: boolean;
  }) => {
    try {
      const baseUrl = liveData
        ? 'https://id.sonio-group.com/api/console/v1/sessions/stats'
        : 'https://staging.id.sonio-group.com/api/console/v1/sessions/stats';
      const response = await axios.get(`${baseUrl}?authOrgId=${authOrgId}`, {
        headers: {
          Authorization: `Bearer ${__raw}`,
        },
      });

      return { data: response.data, period };
    } catch (error) {
      throw error;
    }
  },
);

export const fetchYearlyStatistics = createAsyncThunk(
  'statistics/fetchYearlyStatistics',
  async ({
    __raw,
    year,
    liveData,
    authOrgId,
  }: {
    __raw: string;
    year: number;
    liveData: boolean;
    authOrgId: string;
  }) => {
    try {
      const baseUrl = liveData
        ? `https://id.sonio-group.com/api/console/v1/sessions/year?year=${year}`
        : `https://staging.id.sonio-group.com/api/console/v1/sessions/year?year=${year}`;
      const response = await axios.get(`${baseUrl}?authOrgId=${authOrgId}`, {
        headers: {
          Authorization: `Bearer ${__raw}`,
        },
      });

      const data: MonthlyStats[] = response.data.months.map((month: any) => ({
        Date: {
          Year: month.year,
          Month: month.month,
        },
        TotalCount: month.totalCount,
        TotalValidationsPass: month.totalValidationsPass,
        TotalValidationsNeedsReview: month.totalValidationsNeedsReview,
        TotalValidationsFail: month.totalValidationsFail,
      }));

      return data;
    } catch (error) {
      throw error;
    }
  },
);

export const statisticSlice = createSlice({
  name: 'sliceStatistics',
  initialState,
  reducers: {
    setSelectedDateStats: (state, action) => {
      state.selectedDateStats = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchStatistics.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchStatistics.fulfilled,
        (state, action: PayloadAction<{ data: any; period: string }>) => {
          state.isLoading = false;
          state.sliceStatistics = action.payload.data;
          const periodData = action.payload.data[action.payload.period];
          if (periodData) {
            state.selectedDateStats = periodData;
          }
          state.error = null;
        },
      )
      .addCase(fetchStatistics.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch stats';
        state.sliceStatistics = [];
      })
      .addCase(fetchYearlyStatistics.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchYearlyStatistics.fulfilled,
        (state, action: PayloadAction<MonthlyStats[]>) => {
          state.isLoading = false;
          state.yearlyStatistics = action.payload;
          state.error = null;
        },
      )
      .addCase(fetchYearlyStatistics.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch yearly stats';
      });
  },
});

export const { setSelectedDateStats } = statisticSlice.actions;
export default statisticSlice.reducer;
