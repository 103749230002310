import { Handle, Position, useStore } from 'reactflow';

const connectionNodeIdSelector = state => state.connectionNodeId;
function GroupNode({ data, id }) {
  const connectionNodeId = useStore(connectionNodeIdSelector);

  const isTarget = connectionNodeId && connectionNodeId !== id;

  return (
    <div className="relative">
      <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
        <div className="font-mono font-bold text-black !text-lg">
          {data.name}
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Right}
        className="right-[-25px] w-3 h-3 border-2 border-black bg-transparent top-[5.5rem]"
      />
      <Handle
        type="source"
        position={Position.Right}
        className="right-[-25px] w-3 h-3 border-2 border-black bg-transparent top-[11.5rem]"
      />
      <Handle
        type="source"
        position={Position.Right}
        className="right-[-25px] w-3 h-3 border-2 border-black bg-transparent top-[17.5rem]"
      />
      <Handle
        type="target"
        position={Position.Left}
        className={`border ${
          isTarget
            ? 'border-dashed border-2 border-black bg-transparent'
            : 'bg-transparent border-transparent'
        } w-3 h-3 left-[-26px] top-[175px]`}
      />
    </div>
  );
}
export default GroupNode;
