const logout = () => {
  setSession(null, null);
};

export const setSession = (
  idToken: string | null,
  refreshToken: string | null,
): void => {
  if (idToken) {
    localStorage.setItem('idToken', idToken);
  } else {
    localStorage.removeItem('idToken');
  }
  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  } else {
    localStorage.removeItem('refreshToken');
  }
};

export { logout };
