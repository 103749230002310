import React, { useCallback, useRef, useState } from 'react';
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  MarkerType,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from 'reactflow';

import ContextMenu from './Context';
import CustomNode from './Custom';
import ConnectionLine from './customLine';
import GroupNode from './Group';
import Items from './items';

import 'reactflow/dist/style.css';
import './index.css';

const nodeTypes = {
  custom: CustomNode,
  group: GroupNode,
};

const initialNodes = [
  {
    id: '1',
    type: 'custom',
    data: { name: 'Start' },
    position: { x: 0, y: 0 },
    className: 'light',
  },
  {
    id: '2',
    type: 'group',
    data: { name: 'Document select' },
    position: { x: 900, y: -100 },
    className: 'light shadow-2xl !border-non !text-black',
    style: { width: 300, height: 350 },
  },
];

let id = 0;
const getId = () => `dndnode_${id++}`;

const DnDFlow = () => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [menu, setMenu] = useState(null);
  const ref = useRef(null);

  const onConnect = useCallback(
    params => setEdges(eds => addEdge(params, eds)),
    [setEdges],
  );

  const onNodeContextMenu = useCallback(
    (event, node) => {
      // Prevent native context menu from showing
      event.preventDefault();

      // Calculate position of the context menu. We want to make sure it
      // doesn't get positioned off-screen.
      const pane = ref.current.getBoundingClientRect();

      setMenu({
        id: node.id,
        top: event.clientY < pane.height - 200 && event.clientY,
        left: event.clientX < pane.width - 200 && event.clientX,
        right: event.clientX >= pane.width - 200 && pane.width - event.clientX,
        bottom:
          event.clientY >= pane.height - 200 && pane.height - event.clientY,
      });
    },
    [setMenu],
  );
  const onPaneClick = useCallback(() => setMenu(null), [setMenu]);

  const defaultEdgeOptions = {
    style: { strokeWidth: 2, stroke: 'black' },
    type: 'floating',
    markerEnd: {
      type: MarkerType.Arrow,
      color: 'black',
    },
  };

  const onDragOver = useCallback(event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    event => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');
      const parentNode = event.dataTransfer.getData(
        'application/reactflow/parent',
      );
      const nodePositionString = event.dataTransfer.getData(
        'application/reactflow/position',
      );
      const nodePosition = nodePositionString
        ? JSON.parse(nodePositionString)
        : null;

      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = nodePosition
        ? nodePosition
        : reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
          });

      const newNode = {
        id: getId(),
        type: 'custom',
        position: position,
        data: { name: `${type}` },
        className: 'light',
        parentNode: `${parentNode}`,
      };

      setNodes(nds => nds.concat(newNode));
    },
    [reactFlowInstance, setNodes],
  );

  return (
    <>
      <div className="w-full align-center pl-10">
        <div>
          <h3 className="text-3xl font-bold">Flow Builder</h3>
          <p className="text-sm">Use this page to manage your user flow.</p>
        </div>
      </div>
      <div className="dndflow bg-white m-10">
        <ReactFlowProvider>
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
            <ReactFlow
              ref={ref}
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              onDrop={onDrop}
              nodeTypes={nodeTypes}
              onDragOver={onDragOver}
              defaultEdgeOptions={defaultEdgeOptions}
              connectionLineComponent={ConnectionLine}
              onPaneClick={onPaneClick}
              onNodeContextMenu={onNodeContextMenu}
              fitView
            >
              <Controls />
              <Background />
              {menu && <ContextMenu onClick={onPaneClick} {...menu} />}
            </ReactFlow>
          </div>
          <Items />
        </ReactFlowProvider>
      </div>
    </>
  );
};

export default DnDFlow;
