import { useDispatch } from 'react-redux';
import ToggleButton from '@mui/material/ToggleButton';
import { useAppSelector } from 'src/hooks/useStore';
import { setLiveData } from 'src/store/slices/transaction.slice';

export default function StandaloneToggleButton() {
  const dispatch = useDispatch();
  const { liveData } = useAppSelector(state => state.sliceTransaction);
  const handleToggle = () => {
    dispatch(setLiveData(!liveData));
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
      <p style={{ marginRight: '16px', color: '#000', fontWeight: 700 }}>
        Display Test Data
      </p>
      <ToggleButton
        value="check"
        selected={liveData}
        onChange={handleToggle}
        style={{
          backgroundColor: liveData ? '#f44336' : '#4caf50',
          color: '#fff',
          borderRadius: '20px',
          padding: '8px 16px',
          textTransform: 'uppercase',
          fontWeight: 'bold',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
        }}
      >
        {liveData ? 'Off' : 'On'}
      </ToggleButton>
    </div>
  );
}
