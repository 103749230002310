import type { ReactNode } from 'react';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import PersonIcon from '@mui/icons-material/Person';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Transactions',
        icon: BackupTableTwoToneIcon,
        link: '/transactions',
        items: [
          {
            name: 'overview',
            link: '/transactions/sessions',
          },
          {
            name: 'statistics',
            link: 'transactions/statistics',
          },
        ],
      },
      {
        name: 'Users',
        icon: PersonIcon,
        link: '',
        items: [
          {
            name: 'List',
            link: '/users',
          },
        ],
      },
      // {
      //   name: 'Flows',
      //   icon: BackupTableTwoToneIcon,
      //   link: '',
      //   items: [
      //     {
      //       name: 'Builder',
      //       link: '/flows/builder',
      //       badge: '',
      //     },
      //     {
      //       name: 'Rules',
      //       link: '/flows/rules',
      //       badge: '',
      //     },
      //   ],
      // },
    ],
  },
];

export default menuItems;
