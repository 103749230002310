import { Handle, Position, useStore } from 'reactflow';

const connectionNodeIdSelector = state => state.connectionNodeId;
function CustomNode({ data, id }) {
  const connectionNodeId = useStore(connectionNodeIdSelector);

  const isTarget = connectionNodeId && connectionNodeId !== id;
  return (
    <div className="relative">
      <svg
        width={`${40 + 8 * data.name.length}`}
        height="50"
        className="block overflow-visible"
      >
        {/* Define a filter with a drop shadow */}
        <defs>
          <filter id="drop-shadow" x="-20%" y="-20%" width="140%" height="140%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="4" />
            <feOffset dx="2" dy="2" result="offsetblur" />
            <feComponentTransfer>
              <feFuncA type="linear" slope="0.5" />
            </feComponentTransfer>
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>

        <g filter="url(#drop-shadow)">
          <path
            d={`M0,0 L${40 + 8 * data.name.length},0  L${
              50 + 8 * data.name.length
            },25 L${40 + 8 * data.name.length},50 L0,50 z`}
            fill="#f3f5f9"
            strokeWidth="2"
            stroke="#fff"
          ></path>
        </g>
      </svg>

      <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
        <div className="font-mono font-bold text-black text-xs">
          {data.name}
        </div>
      </div>
      {!(
        data.name === 'Passport' ||
        data.name === 'German Residence Permit' ||
        data.name === 'Identity Card'
      ) && (
        <>
          <Handle
            type="source"
            position={Position.Right}
            className="right-[-25px] w-3 h-3 border-2 border-black bg-transparent"
          />
          <Handle
            type="target"
            position={Position.Left}
            className={`border ${
              isTarget
                ? 'border-dashed border-2 border-black bg-transparent'
                : 'bg-transparent border-transparent'
            } w-3 h-3 left-[-6px]`}
          />
        </>
      )}
    </div>
  );
}
export default CustomNode;
