import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export interface OrganizationData {
  id: string;
  name: string;
  display_name: string;
}
interface OrganizationState {
  organizations: Array<OrganizationData>;
  isLoading: boolean;
  selectedOrganization: OrganizationData;
  error: string | null;
}

const initialState: OrganizationState = {
  organizations: [{ id: '', name: 'All', display_name: 'All' }],
  selectedOrganization: null,
  isLoading: false,
  error: null,
};

export const fetchOrganizations = createAsyncThunk(
  '/organization/fetchOrganizations',
  async ({ sub, __raw }: { sub: string; __raw: string }) => {
    try {
      const response = await axios.get(
        `https://dev.id.sonio-group.com/api/console/v1/user/organizations/${sub}`,
        {
          headers: {
            Authorization: `Bearer ${__raw}`,
          },
        },
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
);

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setSelectedOrganization: (state, action) => {
      state.selectedOrganization = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchOrganizations.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchOrganizations.fulfilled, (state, action) => {
        const organizationData = JSON.parse(action.payload.data);
        state.isLoading = false;
        state.organizations = [
          { id: '', name: 'All', display_name: 'All' },
          ...organizationData,
        ];
        if (!state.selectedOrganization) {
          state.selectedOrganization = {
            id: '',
            name: 'All',
            display_name: 'All',
          };
        }
        state.error = null;
      })
      .addCase(fetchOrganizations.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch organizations';
        state.organizations = [];
      });
  },
});

export const { setSelectedOrganization } = organizationSlice.actions;
export default organizationSlice.reducer;
