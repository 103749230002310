import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Header from './Header';
import Sidebar from './Sidebar';

interface SidebarLayout {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayout> = () => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? (
    <>
      <Header />
      <Sidebar />
      <div className="z-5 flex flex-1 sm:pl-[290px]">
        <div className="flex flex-1 flex-col w-full">
          <div className="grow">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  ) : (
    <Outlet />
  );
};

export default SidebarLayout;
